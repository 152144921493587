<script>
import {defineComponent} from 'vue'
import radio from "@/component/helper/form/radio.vue";
import icKa from "@icon/ic-ka.svg";
import Tel from "@icon/ic-telephone.svg";

export default defineComponent({
  name: "CharteredLists",
  components: {radio},
  props: {
    onePerson: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      icons: {icKa, Tel}
    }
  },
  methods: {
    truncateText(text, maxLength, suffix = '...') {
      if (!text || text.length <= maxLength) {
        return text
      }
      return text.slice(0, maxLength) + suffix
    }
  }
})
</script>

<template>
  <div class="CharteredLists">
    <div
      class="border-b BorderColor w-full flex items-center px-5 h-20 cursor-pointer  gap-2"
    >
      <div class="flex items-center gap-5 w-1/2">
        <radio @click.stop />
        <div class="flex items-center gap-3">
          <icon
            :data="icons.icKa"
            height="26"
            width="26"
            class="w-5 h-5"
            original
          />
          {{ onePerson.lastName }} {{ onePerson.firstName }}
        </div>
      </div>
      <div class="w-1/3">
        {{ onePerson.office }}
      </div>
      <div class="w-1/2 Email responsive-large">
        {{ truncateText(onePerson.email, 20) }}
      </div>
      <div class="w-1/3 flex items-center justify-center gap-2">
        <icon
          :data="icons.Tel"
          height="16"
          width="16"
          class="w-5 h-5"
          original
        />
        {{ onePerson.phoneNumber }}
      </div>
      <div class="w-80">
        {{ onePerson.affected.length.toLocaleString() }}
      </div>
      <div class="w-80">
        {{ '----' }}  FCFA
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@media (max-width: 1600px) {
  .WidthContain{
    font-size: 14px;
    // justify-content: space-between; /* Distribution avec espace entre les éléments */  
  }
}
</style>
