<template>  
  <div>  
    <apexchart  
      type="bar"  
      :options="chartOptions"  
      :series="chartSeries"  
    />  
  </div>  
</template>  
    
  <script>  
  import { defineComponent } from 'vue';  
  import { ApexCharts } from 'vue3-apexcharts';  
    
  export default defineComponent({  
    components: {  
      apexchart: ApexCharts,  
    },  
    data() {  
      return {  
        chartOptions: {
          chart: {  
            toolbar: {  
              show: false // Désactive la barre d'outils  
            },  
            title: {  
              text: 'Exemple de Graphique en Colonnes',  
              align: 'center',  
            },  
          },  
          xaxis: {  
            categories: [''], // Catégories de l'axe des x  
          },  
          plotOptions: {  
            bar: {  
              horizontal: false, // Colonne horizontale  
            },  
          },  
          dataLabels: {  
            enabled: true, // Désactiver les étiquettes de données  
          },  
        },  
        chartSeries: [  
          {  
            name: 'Ventes',  
            data: [30, 40, 35, 50, 49],  
            color: '#3D2FAD', // Ajout de la couleur pour les barres de Ventes  
          },  
        ],  
      };  
    },  
  });  
  </script>  
    
  <style scoped>  
  /* Ajoutez des styles si nécessaire */  
  </style>