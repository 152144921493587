<script>
import {createAffectation} from "@/component/cdevs/allFunctions";

export default {
  emits: ['closePopup2'],  // L'événement pour fermer le popup
  props: {
    showPopup3: {
      type: Boolean,
      required: true,
    },
    isData: {
      type: Object
    },
    onePeople: {
      type: Object
    }
  },
  data() {
    return {
      charge: false
    }
  },
  methods: {
    closePopup() {
      this.$emit('closePopup2'); // Émettre l'événement pour fermer le popup
    },
    async createNew() {
      if (this.isData) {
        this.charge = true;
        const tab = []
        tab.push(this.isData.id)
        const data = {
          apiKey: process.env.VUE_APP_OLD_X_PRIVATE_KEY,
          companyId: tab,
          userId: this.onePeople.id,
          // category: "string"
        }
        await createAffectation(data).then(response => {
          console.log(response)
          this.$toast.success("Nouvelle affectation réussie");
          this.$emit('closePopup2');
        }).catch(error => {
          console.log(error);
          this.charge = false;
          this.$toast.error("Une erreur est survenue");
        })
      }
    }
  },
}
</script>

<template>
  <div
    v-if="showPopup3"
    class="popup-container"
  >
    <!-- Deuxième Popup qui s'affiche après l'enregistrement -->
    <div class="popup-overlay">
      <div class="popup-content bg-white p-5 relative">
        <div class="flex font-c7 text-c22 colorBorderB pb-5 px-5">
          Approuver la demande
        </div>
        <div class="mt-10">
          <div class="text-base font-c4 text-c18 text-center ">
            Vous êtes sur le point d'affecter
            la gestion de la comptabilité de <span class="font-c8"> {{ isData.fullName }} </span> à un expert comptable. Veuillez cliquer sur le bouton <span class="italic font-c5">Approuver la demande</span> pour valider.
          </div>
          <div class="flex justify-end items-center mt-5">
            <div
              class="text-red-500 mt-2 text-sm font-c5 ml-5 cursor-pointer annulerr px-7 py-3 rounded-8"
              @click="closePopup"
            >
              Annuler
            </div>
            <div
              class="text-red-500 mt-2 text-sm font-c5 ml-5 cursor-pointer save px-10 py-3 rounded-8 text-white"
              @click="createNew"
            >
              <easy-spinner
                v-if="charge"
                type="dots"
                size="20"
              />
              <div v-else>
                Approuver la demande
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
.annulerr{
    background-color: #EFF4FB;
}
.save{
    background-color: #241A72;
}
.colorBorderB{
    border-bottom: 3px solid #E7EEF8;
}
.bgInpout{
    background-color: #F0F2F6;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.popup-content {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.place {
  display: flex;
  align-items: center;
  justify-content: center;
}

.relative {
  position: relative;
  width: 28%;
}

</style>
