<script >
import CharteredAccountants from '../../component/cdevs/charteredAccountants/index.vue'

export default {
  components:{
    CharteredAccountants
  }
}
</script>

<template>
  <div class="p-6 all">
    <div>
      <CharteredAccountants />
    </div>
  </div>
</template>

<style scoped>
.all{
  min-height: 100vh;
}
</style>
