<script>
import returnPartenaire from '../../../assets/icons/ic-returnPartenaire.svg';
import AddPhoto from '../../../assets/icons/ic-addPhoto.svg'
import icMultiselect from '../../../assets/icons/ic-multiselect.svg'
import Boutton from '../../../component/helper/add/button.vue';
import { createCharteredAccountant, uploadFile } from "@/component/cdevs/allFunctions";


export default {
  name: 'CreateAccountant',
  components:{
    Boutton,
  },
emits: ['closeCreate'],
  data() {
    return {
      file: null,
      picture: '',
      firstname: '',
      lastname: '',
      office: '',
      email: '',
      phone: '',
      charge: false,
      showDropdown: {
        email: false,
        phone: false
      },
      selectedEmail: '',
      selectedPhone: '',
      emailOptions: ['email1@example.com', 'email2@example.com', 'email3@example.com'],
      phoneOptions: ['1234567890', '0987654321', '1122334455'],
      icons: {
        returnPartenaire,
        AddPhoto,
        icMultiselect
      }
    };
  },
  methods: {
    // Selection of file
    chooseFile() {
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', 'image/*')

      input.onchange = (e) => {
        this.file = e.target.files[0]
        console.log(this.file)

        const reader = new FileReader()
        reader.onloadend = () => {
          const base64Image = reader.result
          console.log('Base64 Image:', base64Image)
          this.picture = base64Image
        }
        reader.readAsDataURL(this.file)
      }

      input.click()
    },

    // Function to upload file and get url
    async createFileUrl(file) {
      const formData = new FormData()
      formData.append('file', file)

      try {
        const response = await uploadFile(formData)
        console.log('Fichier téléchargé avec succès :', response.url)
        return response.url
      } catch (error) {
        console.error('Erreur lors du téléchargement du fichier :', error)
      }
    },

    // Function to create new accountant
    async createAccountant() {
      if (this.lastname === '' || this.firstname === '' || this.office === '' || this.email === '' || this.phone === '') {
        return this.$toast.error('Veuillez remplir tous les champs')
      }

      this.charge = true;
      const url = await this.createFileUrl(this.file);
      const data = {
        apiKey: process.env.VUE_APP_OLD_X_PRIVATE_KEY,
        email: this.email,
        firstName: this.firstname,
        lastName: this.lastname,
        office: this.office,
        phoneNumber: this.phone,
        pictureUrl: url,
        permissions: ["read", "write", "admin"]
      }
      await createCharteredAccountant(data).then(response => {
        console.log(response)
        this.charge = false;
        this.$toast.success('Expert comptable ajouté avec succès');
        this.$emit('closeCreate', true)
      }).catch(error => {
        console.log(error)
        this.charge = false;
        this.$toast.error('Une erreur est survenue');
      })
    },

    toggleDropdown(type) {
      this.showDropdown[type] = !this.showDropdown[type];
    },
    selectOption(type, option) {
      if (type === 'email') {
        this.selectedEmail = option;
        this.showDropdown.email = false;
      } else if (type === 'phone') {
        this.selectedPhone = option;
        this.showDropdown.phone = false;
      }
    },
  }
}
</script>

<template>
  <div class="w-3/5 WidthContain mx-auto flex flex-col justify-center">
    <div class="flex items-center gap-3 NewPart mb-10">
      <icon
        :data="icons.returnPartenaire"
        height="48"
        width="53"
        original
        @click="$emit('closeCreate', false)"
        class="cursor-pointer"
      />
      Nouveau comptable
    </div>
    <div class="bg-white rounded-14 border-gray border px-14 py-10 pb-20">
      <div class="flex">
        <div class="flex items-center gap-5">
          <div>
            <div v-if="picture === ''">
              <img
                src="../../../assets/images/png/imgCamera.png"
                width="126px"
                height="126px"
                alt="fececam"
                class="cursor-pointer"
              >
            </div>
            <div v-else>
              <div
                class="circular rounded-full w-32 h-32 bg-center bg-no-repeat bg-contain"
                :style="{ 'background-image': 'url(' + picture + ')' }"
              />
            </div>
          </div>
          <div class="space-y-3">
            <div
              class="text-start flex items-center gap-2 addlogo px-5 py-2 rounded-8 border border-gray cursor-pointer w-52"
              @click="chooseFile"
            >
              <icon
                :data="icons.AddPhoto"
                height="13"
                width="14"
                original
              />
              Ajouter un logo
            </div>
            <div class="formatColor">
              Image au format JPG, JPEG ou PNG, 1Mo maximum
            </div>
          </div>
        </div>
      </div>
      <div class="space-y-5 mt-10">
        <div class="flex items-center justify-between w-full gap-7">
          <div class="w-full text-left nameInput">
            Nom
            <input
              v-model="lastname"
              type="text"
              class="w-full h-14 rounded-10 bgInput px-6 mt-2 mt-2"
              placeholder="--------"
            >
          </div>
          <div class="w-full text-left nameInput">
            Prénoms
            <input
              v-model="firstname"
              type="text"
              class="w-full h-14 rounded-10 bgInput px-6 mt-2"
              placeholder="--------"
            >
          </div>
        </div>
        <div class="text-left nameInput">
          Cabinet
          <input
            v-model="office"
            type="text"
            class="w-full h-14 rounded-10 bgInput px-6 mt-2"
            placeholder="--------"
          >
        </div>
        <div class="flex items-center justify-between gap-7">
          <div class="w-full text-left nameInput">
            Email professionnel
            <input
              v-model="email"
              type="email"
              class="w-full h-14 rounded-10 bgInput px-6 mt-2"
              placeholder="--------"
            >
          </div>
          <div class="w-full text-left nameInput">
            Numéro de téléphone
            <input
              v-model="phone"
              type="text"
              class="w-full h-14 rounded-10 bgInput px-6 mt-2"
              placeholder="--------"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white rounded-14 border-gray border px-14 py-10 pb-14 mt-10">
      <div class="text-left Affectation mb-5">
        Affectation
      </div>
      <div class="flex items-center justify-between gap-7">
        <!-- Email professionnel -->
        <div class="w-full text-left nameInput">
          Email professionnel
          <div class="relative w-full">
            <input
              type="text"
              class="w-full h-14 rounded-10 bgInput px-6 mt-2 relative"
              placeholder="--------"
              readonly
              @click="toggleDropdown('email')"
              v-model="selectedEmail"
            >
            <icon
              :data="icons.icMultiselect"
              height="7"
              width="11"
              alt="Retour Partenaire"
              original
              class="absolute right-6 top-1/2 transform -translate-y-1/2 cursor-pointer"
              @click="toggleDropdown('email')"
            />
            <div
              v-if="showDropdown.email"
              class="absolute left-0 right-0 bg-white border border-gray  rounded-10 shadow-md mt-1 z-10 max-h-48 overflow-y-auto"
            >
              <ul>
                <li
                  v-for="(option, index) in emailOptions"
                  :key="index"
                  @click="selectOption('email', option)"
                  class="px-6 py-2 hover:bg-gray-100 cursor-pointer"
                >
                  {{ option }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Numéro de téléphone -->
        <div class="w-full text-left nameInput">
          Numéro de téléphone
          <div class="relative w-full">
            <input
              type="text"
              class="w-full h-14 rounded-10 bgInput px-6 mt-2 relative"
              placeholder="--------"
              readonly
              @click="toggleDropdown('phone')"
              v-model="selectedPhone"
            >
            <icon
              :data="icons.icMultiselect"
              height="7"
              width="11"
              alt="Retour Partenaire"
              original
              class="absolute right-6 top-1/2 transform -translate-y-1/2 cursor-pointer"
              @click="toggleDropdown('phone')"
            />
            <div
              v-if="showDropdown.phone"
              class="absolute left-0 right-0 bg-white border border-gray  rounded-10 shadow-md mt-1 z-10 max-h-48 overflow-y-auto"
            >
              <ul>
                <li
                  v-for="(option, index) in phoneOptions"
                  :key="index"
                  @click="selectOption('phone', option)"
                  class="px-6 py-2 hover:bg-gray-100 cursor-pointer"
                >
                  {{ option }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-8">
      <Boutton
        label="Enregistrer"
        weight="500"
        background="#241A72"
        padding="8px 38px"
        size="16"
        :charge="charge"
        @click="createAccountant"
      />
    </div>
  </div>
</template>

<style>
@media (max-width: 1440px) {
  .WidthContain{
    width: 80%;
  }
}
@media (max-width: 1024px) {
  .WidthContain{
    width: 90%;
  }
}
@media (max-width: 768px) {
  .WidthContain{
    width: 100%;
  }
}

.circular {
  border-radius: 9999px;
}

.svg-fill{
  fill: transparent;
}

.NewPart{
  font-Weight:500 ;
  font-size: 28px;
}
.addlogo{
  font-Weight:500 ;
  font-size: 15px;
}
.bgInput{
  background-color: #F0F2F6;
}
.formatColor{
  color: #B3B5B6;
  font-size: 14px;
  font-weight: 400;
}
.nameInput{
  font-weight: 400;
  font-size: 14px;
  color: #83838C;
}
.Affectation{
  font-weight: 500;
  font-size: 20px;
}
</style>
