<template>  
  <div class="AffectedLists">  
    <div
      class="flex justify-between items-center font-c4 text-c16 TitleColor px-10 py-5"
    >  
      <div class="w-1/3 text-left flex items-center gap-3">  
        <div>  
          <icon  
            :data="icons.entreCommence"  
            height="24"  
            width="20"  
            original  
            class="cursor-pointer"  
          />  
        </div>  
        {{ oneList.companyData.fullName }}  
      </div>  
      <div class="w-1/4 text-left -ml-14">  
        {{ oneList.companyData.categorie }}  
      </div>  
      <div class="w-1/5 text-left">  
        {{ '----' }}  
      </div>  
      <div class="w-1/5 text-left">  
        {{ '----' }}  
      </div>  
      <div class="w-1/5 text-left flex justify-between rounded-100">
        <div class="all-status">  
          <div  
            v-if="oneList.affectation.status === 'WAITING'"
            class="wait border px-7 py-2 rounded-100"
          >  
            En attente  
          </div>  
          <div  
            v-if="oneList.affectation.status === 'PENDING'"
            class="border px-7 py-2 rounded-100 BGColorAttente"
          >  
            En cours  
          </div>  
          <div  
            v-if="oneList.affectation.status === 'END'"
            class="finish border px-7 py-2 rounded-100"
          >  
            Terminée  
          </div>  
        </div>  
        <div class="relative">  
          <div  
            @click="isOption = !isOption"  
            class="cursor-pointer p-2"  
          >  
            <icon  
              :data="icons.options"  
              height="17"  
              width="3"  
              original  
              class="cursor-pointer"  
            />  
          </div>  
          <div  
            v-if="isOption"  
            class="options absolute mt-2 bg-white border border-gray rounded-md shadow-md p-4 w-64 rounded-10"  
          >  
            <div  
              class="text-left cursor-pointer mb-2"  
              @click="showCreateAccountant"
            >  
              Modifier les informations  
            </div>  
            <div class="text-left cursor-pointer redText">  
              Changer le comptable  
            </div>  
          </div>  
        </div>  
      </div>  
    </div>  
  </div>  
</template>  

<script>  
import { defineComponent } from 'vue';  
import options from "@icon/ic-options.svg";  
import entreCommence from "@icon/ic-entreCommence.svg";  
// import CreateAccountant from "@/component/cdevs/charteredAccountants/createAccountant.vue";  

export default defineComponent({  
  name: "AffectedLists",  
  components: {  
  },  
  props: {  
    oneList: Object  
  },  
  emits: ['OpenCreatAccount'],
  data() {  
    return {  
      icons: { options, entreCommence },  
      isOption: false,  
    }  
  },  
  methods:{
    showCreateAccountant() {
      this.$emit('OpenCreatAccount')
    }
  }
});  
</script>

<style scoped lang="scss">
.redText{
  color: #F04C36;
}
.wait {
  border: 1px solid #3160F9;
  color: #3160F9;
  background: #3160F91A;
}
.finish {
  border: 1px solid #15C641;
  color: #15C641;
  background: #E3FDE9;
}
.BGColorAttente{
  background-color: #fda40027;
  color: #FDA300;
}
.options {
  left: -196px;
  font-weight: 400;
}
@media (max-width: 1440px) {
  .options {
    font-size: 14px;
  }
}
</style>
