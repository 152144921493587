<script>
import entreprise from '../../../assets/icons/ic-entreprise.svg'
import approuvedemande from './approuvedemande.vue';
import http from "@/plugins/https";
import apiroutes from "@/router/api-routes";
import {VueSvgIcon} from "@yzfe/vue3-svgicon";
import ic_enterprise from "@/assets/icons/ic_society-small.svg";

export default {
  name: 'AffectationPopup',
    components:{
      VueSvgIcon,
        approuvedemande
    },
  data() {
    return {
      icons: { entreprise, ic_enterprise },
      search: '',
      showPopup3: false,
      suggestions: [],
      filteredSuggestions: [],
      selected: null,
      showOptions: false,
      timer: null
    };
  },
  emits: ['closePopup', 'closeAffect'],  // Ajouter l'événement 'showSecondPopup'
  props: {
    showPopup2: {
      type: Boolean,
      required: true,
    },
    onePeople: {
      type: Object
    }
  },
  created() {
    this.fullClient()
  },
  methods: {
    fetchSuggestions() {
      // Debounce : Attendre 300ms avant de filtrer les suggestions
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        if (this.search.length > 1) {
          this.filteredSuggestions = this.suggestions.filter(suggestion =>
              suggestion.fullName.toLowerCase().includes(this.search.toLowerCase())
          );
          this.showOptions = this.filteredSuggestions.length > 0;
        } else {
          this.showOptions = false;
          this.filteredSuggestions = [];
        }
      }, 300);
    },
    selectSuggestion(suggestion) {
      this.search = suggestion.fullName;
      this.selected = suggestion;
      this.showOptions = false;
    },
    closeOptions() {
      setTimeout(() => {
        this.showOptions = false;
      }, 200);
    },
    fullClient () {
      http.post(apiroutes.baseURL + apiroutes.allCommerce)
          .then(response => {
            this.suggestions = response.reverse()
            console.log('allUser', response)
          })
          .catch(error => {
            console.log(error)
          })
    },
    closePopup() {
      this.$emit('closePopup');
    },
    openPopup() {
      if (this.selected) {
        this.$emit('closePopup');
        this.showPopup3 = true;    // Affiche le deuxième popup
      } else {
        this.$toast.error('Veuilez sélectionner une entreprise');
      }
    },
  closePopup2() {
    console.log('Popup is closinggg');
    this.showPopup3 = false;   // Désactive le popup
    this.$emit('closeAffect')
  },
  },
}
</script>

<template>
  <div
    class="popup-overlay"
    v-if="showPopup2"
  >
    <div class="popup-content bg-white w-2/5 p-5 relative">
      <div class="flex font-c7 text-c22 colorBorderB pb-5 px-5">
        Nouvelle affectation
      </div>
      <div class="mt-10">
        <div class="font-medium text-base ml-5">
          <div class="mb-2 text-left text-82">
            Entrez le nom de l’entreprise
          </div>
          <div class="input-bloc relative">
            <!-- Input de recherche -->
            <input
              v-model="search"
              type="text"
              class="h-14 bgInpout w-full rounded-10 pl-5"
              placeholder="Rechercher une entreprise..."
              @input="fetchSuggestions"
              @focus="showOptions = true"
              @blur="closeOptions"
            >

            <!-- Suggestions -->
            <div
              v-if="showOptions && filteredSuggestions.length"
              class="options absolute z-50 w-full mt-2 bg-white rounded-10 p-2 maw-h-28 overflow-y-scroll"
            >
              <div
                v-for="(suggestion, index) in filteredSuggestions"
                :key="index"
                class="one-option flex items-center cursor-pointer p-2 hover:bg-gray-100"
                @mousedown="selectSuggestion(suggestion)"
              >
                <div class="icon">
                  <vue-svg-icon
                    :data="icons.ic_enterprise"
                    original
                    width="18px"
                    height="18px"
                  />
                </div>
                <div class="ml-2 text-black text-sm 2xl:text-base">
                  {{ suggestion.fullName }}
                </div>
              </div>
            </div>
          </div>

          <div v-if="selected" class="flex items-start gap-4 w-full mt-6 border rounded-14 border-gray p-5">
            <vue-svg-icon
              :data="icons.ic_enterprise"
              original
              width="32px"
              height="32px"
              color="#466AFA"
            />
            <div class="">
              <div class="w-full text-left text-c18">
                {{ selected.fullName }}
              </div>
              <div class="w-full flex text-83">
                {{ selected.categorie }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end items-center mt-5">
          <div
            class="text-red-500 mt-2 text-sm ml-5 cursor-pointer annulerr px-7 py-3 rounded-8"
            @click="closePopup"
          >
            Annuler
          </div>
          <div
            class="text-red-500 mt-2 text-sm ml-5 cursor-pointer save px-10 py-3 rounded-8 text-white"
            @click="openPopup()"
          >
            Enregistrer
          </div>
        </div>
      </div>
    </div>
  </div>
  <approuvedemande
    :show-popup3="showPopup3"
    :is-data="selected"
    :one-people="onePeople"
    @closePopup2="closePopup2"
  />
</template>


<style scoped>
.options {
  box-shadow: 0px 2px 28px 0px #9A9A9A26;
  max-height: 10rem;
}
.one-option:hover {
  background: #F0F2F6;
  border-radius: 10px;
}
.annulerr{
    background-color: #EFF4FB;
}
.save{
    background-color: #241A72;
}
.colorBorderB{
    border-bottom: 3px solid #E7EEF8;
}
.bgInpout{
    background-color: #F0F2F6;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.popup-content {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 25rem;
}
.place {
  display: flex;
  align-items: center;
  justify-content: center;
}

.relative {
  position: relative;
}

</style>
