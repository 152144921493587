<script>
import Boutton from '../../../component/helper/add/button.vue';
import CreateAccountant from "@/component/cdevs/charteredAccountants/createAccountant.vue";
import AllCharteredAccountant from "@/component/cdevs/charteredAccountants/AllCharteredAccountant.vue";
import AccountantDetails from "@/component/cdevs/charteredAccountants/AccountantDetails.vue";

export default {
  components: {
    AccountantDetails,
    AllCharteredAccountant,
    CreateAccountant,
    Boutton,
  },
  data() {
    return {
      showNewCompta: false,
      isDetails: false,
      selectedPerson: null
    };
  },
  methods: {
    openDetails(value) {
      this.isDetails = true;
      this.selectedPerson = value;
    },

    closeDetails(value) {
      this.selectedPerson = null;
      this.isDetails = value;
    },

    closeCreate(value) {
      if (value) {
        console.log(value)
      }
      this.showNewCompta = false
    }
  }
}
</script>

<template>
  <div class="p-6 all">
    <div class="begin">
      <div
        v-if="!showNewCompta && !isDetails"
        class="content"
      >
        <div class="header flex items-center justify-between mb-12">
          <div class="w-2/3 font-c7 text-left text-c34 2xl:text-c42 text-black">
            Experts comptables
          </div>
          <div>
            <Boutton
              label="Nouveau comptable"
              weight="500"
              background="#241A72"
              padding="8px 38px"
              size="16"
              @click="showNewCompta = true"
            />
          </div>
        </div>

        <div class="body">
          <all-chartered-accountant @open-details="openDetails" />
        </div>
      </div>
    </div>


    <!-- Create new accountant page -->
    <div v-if="showNewCompta">
      <create-accountant @closeCreate="closeCreate" />
    </div>

    <!-- One accountant details page -->
    <div v-if="isDetails">
      <accountant-details
        :one-person="selectedPerson"
        @close-details="closeDetails"
      />
    </div>
  </div>
</template>

<style>
@media (max-width: 1024px) {
  .all {
    padding: 10px;
  }
}
</style>
