<script>
import IconSearch from '../../../assets/icons/ic-search.svg'
import IconFiltre from '../../../assets/icons/ic-filtre.svg'
import radio from '../../helper/form/radio.vue'
import icKa from '../../../assets/icons/ic-ka.svg'
import Tel from '../../../assets/icons/ic-telephone.svg'
import {getAccountant} from "@/component/cdevs/allFunctions";
import CharteredLists from "@/component/cdevs/charteredAccountants/CharteredLists.vue";

export default {
  name: 'AllCharteredAccountant',
  components: {CharteredLists, radio },
emits: ['openDetails'],
  data() {
    return {
      icons: { IconSearch, IconFiltre, icKa, Tel },
      all_accountant: [],
      charge: false,
      empty: false,
      limit: 100,
      page: 1,
      search: '',
      timer: null
    };
  },

  created() {
    this.getAllChartered(this.limit, this.page)
  },

  methods: {
    cleanObject(obj) {
      return Object.fromEntries(
          Object.entries(obj).filter(([_, value]) => value !== null && value !== undefined && value !== '')
      );
    },

    /* Function to get all chartered accountant */
    async getAllChartered(limit, page, search) {
      this.charge = true;
      const data = {
        apiKey: process.env.VUE_APP_OLD_X_PRIVATE_KEY,
        limit: limit,
        page: page,
        search: search,
        type: 'CERTIFIED_ACCOUNTANT'
      }
      this.cleanObject(data)
      await getAccountant(data).then(response => {
        console.log(response)
        this.all_accountant = response.data
        this.charge = false;
      }).catch(error => {
        console.log(error);
        this.charge = false;
        this.$toast.error('Une erreur est survenue');
      })
    },

    openDetails(item) {
      this.$emit('openDetails', item)
    }
  },

  watch: {
    search(newValue) {
      if (this.timer) {
        clearTimeout(this.timer); // Annuler le précédent timeout
      }

      this.timer = setTimeout(() => {
        this.getAllChartered(this.limit, this.page, newValue);
      }, 300); // Exécuter après 300ms d'inactivité
    },
  },
}
</script>

<template>
  <div class="bg-white w-full rounded-10 p-5">
    <div class="flex justify-start responsive-container">
      <div class="flex items-center justify-start gap-5">
        <div class="w-2/3 flex items-center justify-start border border-gray py-2 rounded-100">
          <icon
            :data="icons.IconSearch"
            height="26"
            width="26"
            class="w-5 h-5 mx-4"
            original
          />
          <input
            v-model="search"
            type="text"
            class=" text-c16 responsive-input"
            placeholder="Rechercher"
          >
        </div>
        <div class="border border-gray p-2 flex items-center rounded-100 gap-2 cursor-pointer responsive-filter">
          <icon
            :data="icons.IconFiltre"
            height="26"
            width="26"
            class="w-5 h-5"
            original
          />
          Filtre
          <div class="bg-red text-white rounded-100 px-2">
            1
          </div>
        </div>
      </div>
    </div>

    <div class="responsive-container">
      <div class="BGGRAY w-full flex items-center TextColor h-14 px-5 rounded-t-10 mt-5">
        <div class="flex items-center gap-5 w-1/2">
          <radio />
          <div>Comptable</div>
        </div>
        <div class="w-1/3">
          Cabinet
        </div>
        <div class="w-1/2">
          Email
        </div>
        <div class="w-1/3">
          Téléphone
        </div>
        <div class="w-80">
          Entp. gérées
        </div>
        <div class="w-80">
          Dernière activité
        </div>
      </div>

      <div
        v-if="charge"
        class="flex justify-center mt-16"
      >
        <easy-spinner
          type="dots"
          size="70"
        />
      </div>

      <div
        class="text-center my-10"
        v-if="all_accountant.length === 0"
      >
        Aucun expert comptable pour le moment
      </div>

      <div class="w-full">
        <CharteredLists
          v-for="(item, i) in all_accountant"
          :key="i"
          :one-person="item"
          @click="openDetails(item)"
        />
      </div>
    </div>
  </div>
</template>

<style>
.BGGRAY{
  background-color: #EFF5F9;
}
.TextColor{
  color: #6B6B75;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
}
.BorderColor{
  border-bottom: 1px solid #E7EEF8;
}
.listeStyle{
  font-weight: 400;
  font-size: 18px;
}
.Email{
  color: #6AA3F2;
}

</style>
