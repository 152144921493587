<script>
import returnPartenaire from '../../../assets/icons/ic-returnPartenaire.svg';
import options from '../../../assets/icons/ic-options.svg';
import Boutton from '../../helper/add/button.vue';
import icVueComptable from '../../../assets/icons/ic-vueComptable.svg';
import ProfilDetails from './profilDetails.vue';
import affectationPopup from './affectationPopup.vue';
import CreateAccountant from "@/component/cdevs/charteredAccountants/createAccountant.vue";  


export default {
  name: 'AccountantDetails',
  components: {
    Boutton,
    ProfilDetails,
    affectationPopup,
    CreateAccountant
  },
  props: {
    onePerson: Object
  },

  data() {
    return {
      icons: {
        returnPartenaire,
        options,
        icVueComptable,
      },
      showCreateAccountant: false,  // État pour contrôler l'affichage de CreateAccountant  
      showPopup: false, // État du popup
      showPopup2: false, // État du popup
    };
  },
  methods: {
    togglePopup() {
      this.showPopup = !this.showPopup; // Affiche ou masque le popup
    },
    togglePopup2() {
      this.showPopup = !this.showPopup; // Affiche ou masque le popup
    },
    confirmAction() {
      console.log('Action confirmée !');
      this.showPopup2 = false; // Ferme le popup après confirmation
    },
    openPopup() {
    this.showPopup2 = true;    // Active le popup
  },
  closePopup() {
    console.log('Popup is closing');
    this.showPopup2 = false;   // Désactive le popup
  },
  OpenCreatAccount() {
    console.log('show is showCreateAccountant');
    this.showCreateAccountant= true;
  }
  },
};
</script>


<template>
  <div
    class=""
    v-if="!showCreateAccountant" 
  >
    <div class="flex justify-between items-center w-full">
      <div class="flex items-center gap-3 font-c5 text-c26">
        <icon
          :data="icons.returnPartenaire"
          height="48"
          width="53"
          original
          @click="$emit('closeDetails', false)"
          class="cursor-pointer"
        />
        Détails du profil
      </div>
      <div class="flex items-center gap-5">
        <div class="">
          <!-- Bouton pour ouvrir le popup -->
          <Boutton
            label="Nouvelle affectation"
            weight="500"
            background="#241A72"
            padding="8px 38px"
            size="16"
            @click="openPopup"
          />
          <div
            class=""
          >
            <affectationPopup
              :show-popup2="showPopup2"
              :one-people="onePerson"
              @close-popup="closePopup"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <ProfilDetails
        :one-person="onePerson"
        @OpenCreatAccount="OpenCreatAccount"
      />
    </div>
  </div>
  <div
    class=""
    v-if="showCreateAccountant"
  >
    <CreateAccountant />
  </div>
</template>

<style >
.relative {
  position: relative;
}
</style>
