import apiRoute from '@/router/api-routes'
import secondHttp from "@/plugins/secondHttps";

/**
 * Client HTTP unifié pour gérer les requêtes
 */
const httpClient = async ({ method, url, data = null, params = null }) => {
    const config = { method, url, data, params }
    const response = await secondHttp(config)

    // Vérification de la réponse
    if (!response || !response) {
        throw new Error(`Réponse invalide de l'API : ${JSON.stringify(response)}`)
    }

    return response
}

// Create chartered accountant
export const createCharteredAccountant = (data) => {
    return httpClient({ method: 'post', url: `${apiRoute.baseURLAccountant}${apiRoute.create_chartered_accountant}`, data })
}

// Get all chartered accountant
export const getAccountant = (data) => {
    return httpClient({ method: 'get', url: `${apiRoute.baseURLAccountant}${apiRoute.get_all_accountant}`, params: data })
}

// Upload file
export const uploadFile = (data) => {
    return httpClient({
        method: 'post',
        url: `${apiRoute.baseURLAccountant}${apiRoute.upload_file}`,
        data,
    })
}

// Get all affected enterprise
export const getAffected = (data) => {
    return httpClient({ method: 'get', url: `${apiRoute.baseURLAccountant}${apiRoute.get_all_affected}`, params: data })
}

// Create new affectation
export const createAffectation = (data) => {
    return httpClient({
        method: 'post',
        url: `${apiRoute.baseURLAccountant}${apiRoute.create_affectation}`,
        data,
    })
}
