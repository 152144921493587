
<script>
import AffectedLists from "@/component/cdevs/charteredAccountants/AffectedLists.vue";
import {getAffected} from "@/component/cdevs/allFunctions";

export default {
  name: 'AccountantAffected',
  components: {AffectedLists},
  props: {
    onePerson: Object
  },
  emits: ['OpenCreatAccount'],
  data() {
    return {
      all_affected: [],
      charge: false,
      empty: false,
      limit: 100,
      page: 1,
      search: '',
      timer: null
    };
  },

  created() {
    this.getAllCharteredEnterprise(this.limit, this.page, this.search)
  },

  methods: {
    cleanObject(obj) {
      return Object.fromEntries(
          Object.entries(obj).filter(([_, value]) => value !== null && value !== undefined && value !== '')
      );
    },
    OpenCreatAccount() {
    console.log('show is showCreateAccountant');
    this.$emit('OpenCreatAccount')
    },

    /* Function to get all affected enterprise */
    async getAllCharteredEnterprise(limit, page, search) {
      this.charge = true;
      const data = {
        apiKey: process.env.VUE_APP_OLD_X_PRIVATE_KEY,
        limit: limit,
        page: page,
        search: search,
        userId: this.onePerson.id
      }
      this.cleanObject(data)
      await getAffected(data).then(response => {
        console.log(response)
        this.all_affected = response.data
        this.charge = false;
      }).catch(error => {
        console.log(error);
        this.charge = false;
        this.$toast.error('Une erreur est survenue');
      })
    },
  },
};
</script>


<template>
  <div class="mt-5 bg-white rounded-14">
    <div class="flex justify-between items-center font-c4 text-c16 TitleColor px-10 py-5 BOrderCoor">
      <div
        class="w-1/3 text-left
      "
      >
        Entreprise/Commerce
      </div>
      <div class="w-1/4 text-left -ml-14">
        Catégories
      </div>
      <div class="w-1/5 text-left">
        Zone/Région
      </div>
      <div class="w-1/5 text-left">
        Téléphone
      </div>
      <div class="w-1/5 text-left">
        Prise en main
      </div>
    </div>

    <div
      v-if="charge"
      class="flex justify-center mt-16"
    >
      <easy-spinner
        type="dots"
        size="70"
      />
    </div>

    <div
      class="text-center py-10"
      v-if="all_affected.length === 0"
    >
      Aucune affectation pour le moment
    </div>

    <div class="listing">
      <affected-lists
        v-for="(item, i) in all_affected"
        :key="i"
        :one-list="item"
        @OpenCreatAccount="OpenCreatAccount"
        class="border-b border-gray last:border-b-0"
      />
    </div>
  </div>
</template>

<style>
.TitleColor{
    color: #6B6B75;
}
.BOrderCoor{
    border-bottom: 3px solid #CDE1FD;
}
.BGColorAttente{
    background-color: #fda40027;
    color: #FDA300;
}
</style>
