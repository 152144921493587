<script>
import bgProfil from '../../../assets/images/webp/bgProfil.webp';
import imgMonths from '../../../assets/images/webp/imgMonths.webp';
import Profil from '../../../assets/icons/ic-pofil.svg'
import Red from '../../../assets/icons/ic-red.svg'
import Mail from '../../../assets/icons/ic-mail.svg'
import Phone from '../../../assets/icons/ic-phone.svg'
import ManagementEntreprise from '../../../assets/icons/ic-managementEntreprise.svg'
import Delai from '../../../assets/icons/ic-Delai.svg'
import Attente from '../../../assets/icons/ic-attente.svg'
import EtatFinace from '../../../assets/icons/ic-EtatFinace.svg'
import AccountantAffected from './AccountantAffected.vue'
import Graph from './graph.vue';


export default {
  components: { AccountantAffected , Graph },
  props: {
    onePerson: Object
  },
  data() {
    return {
      emits: ['OpenCreatAccount'],
      images: {
        bgProfil,
        imgMonths
      },
      icons:{
        Profil,
        Red,
        Mail,
        Phone,
        ManagementEntreprise,
        Delai,
        Attente,
        EtatFinace
      }
    };
  },
  methods:{
    OpenCreatAccount() {
    console.log('show is showCreateAccountant');
    this.$emit('OpenCreatAccount')
    }
  }
};
</script>

<template>
  <div class="">
    <div class="flex gap-10 justify-between mt-8 contain">
      <div
        class="relative bg-cover bg-center h-72 w-6/12 flex text-white font-bold text-2xl roundedIMG p-10 IMG"
        :style="{ backgroundImage: `url(${images.bgProfil})` }"
      >
        <div class="w-full">
          <div class="flex items-center gap-3">
            <icon
              :data="icons.Profil"
              height="64"
              width="64"
              original
              class="cursor-pointer"
            />
            <div class="">
              <p class="flex profilColorText text-c16 font-c4">
                Profil
              </p>
              <p class="font-c5 text-c24">
                {{ onePerson.lastName }} {{ onePerson.firstName }}
              </p>
            </div>
          </div>
          <div class="flex gap-5 my-3 mt-5 Cabinet font-c3 text-c20">
            <icon
              :data="icons.Red"
              height="26"
              width="7"
              alt="Retour Partenaire"
              original
              class="cursor-pointer"
            />
            {{ onePerson.office }}
          </div>
          <div class="flex items-center gap-2 mt-10">
            <icon
              :data="icons.Mail"
              height="18"
              width="18"
              alt="Retour Partenaire"
              original
              class="cursor-pointer"
            />
            {{ onePerson.email }}
          </div>
          <div class="flex justify-between mt-2">
            <div class="flex items-center gap-2">
              <icon
                :data="icons.Phone"
                height="15"
                width="15"
                alt="Retour Partenaire"
                original
                class="cursor-pointer"
              />
              {{ onePerson.phoneNumber }}
            </div>
            <div class="DerActivity font-c3 text-c16">
              Der. activité : 15/03/23, 13h17
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="bg-white activity roundedIMG border border-gray w-full px-10 py-2 divAtiv">
        <div class="flex justify-between items-center">
          <div class="font-c7 text-c26">
            Activité
          </div>
          <div class="">
            12/07/2024 - 12/08/2024
          </div>
        </div>
        <div class="flex justify-between items-center gap-3 ">
          <div class="flex justify-between bgEntrGere mt- roundedEntre px-7 gap- containEntreGere w-full">
            <div class="flex flex-col justify-center">
              <div class="flex items-center gap-4 font-c6 text-c32">
                <icon
                  :data="icons.ManagementEntreprise"
                  height="34"
                  width="34"
                  alt="Retour Partenaire"
                  original
                  class="cursor-pointer"
                />
                23
              </div>
              <div class="flex text-c15 font-c3 entreGere">
                Entreprises gérées
              </div>
              <div class="flex items-center mt-2 gap-3 text-c12 font-c4 bg-white px-3 py-4 rounded-8">
                <icon
                  :data="icons.Delai"
                  height="18"
                  width="18"
                  alt="Retour Partenaire"
                  original
                  class="cursor-pointer"
                />
                <span class="entreGere">Délai moy. de prise en main :</span>  10 jours
              </div>
            </div>
            <div class="">
              <Graph />
            </div>
          </div>
          <!--  -->
          <div class="w-1/4 space-y-4 mt-9 divPriseMain">
            <div class="">
              <div class="text-left mb-1 font-c4 text-c13 priseetEtat">
                Prise en main en attente
              </div>
              <div class="flex items-center gap-3 font-c5 text-c22">
                <icon
                  :data="icons.Attente"
                  height="29"
                  width="29"
                  alt="Retour Partenaire"
                  original
                  class="cursor-pointer"
                />10
              </div>
            </div>
            <div class="">
              <div class="text-left mb-1 font-c4 text-c13 priseetEtat">
                Etats financiers à clôturer
              </div>
              <div class="flex items-center gap-3 font-c5 text-c22">
                <icon
                  :data="icons.EtatFinace"
                  height="30"
                  width="30"
                  alt="Retour Partenaire"
                  original
                  class="cursor-pointer"
                />09
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
    </div>
    <accountant-affected
      :one-person="onePerson"
      @OpenCreatAccount="OpenCreatAccount"
    />
  </div>
</template>

<style>
.roundedIMG{
    border-radius: 20px;
}
.profilColorText{
    color: #C4D9F6;
}
.DerActivity{
    color: #C4D9F6;
}
.bgEntrGere{
  background-color: #F1F5FA;
}
.roundedEntre{
  border-radius: 22px;
}
.entreGere{
  color: #96969D;
}
.priseetEtat{
  color: #88888F;
}

@media (max-width: 1440px) {
  .IMG {
    width: 65%;
  }
  .contain {
    gap: 20px;
  }
  .containEntreGere {
    gap: 20px;
  }
  .divAtiv {
    padding: 8px 10px;
  }
  .divPriseMain {
    width: 30%;
  }
}

@media (max-width: 1024px) {
  .IMG {
    width: 100%; /* Réduit encore la largeur pour les écrans plus petits */
  }
  .contain {
    gap: 15px; /* Réduction de l'écart */
    display: flex;
    flex-direction: column;    }
  .containEntreGere {
    gap: 15px; /* Réduction similaire pour l'autre conteneur */
  }
  .divAtiv {
    padding: 6px 8px; /* Ajustement des marges pour plus de compacité */
  }
  .divPriseMain {
    width: 40%; /* Augmente la largeur pour mieux remplir l'espace disponible */
  }
}

</style>
