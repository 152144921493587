import axios from 'axios'
import apiRouter from '../router/api-routes'
import store from '../store'

const secondHttp = axios.create({
    baseURL: apiRouter.baseURLAccountant,
    withCredentials: true
})

secondHttp.defaults.headers.common['x-api-key'] = process.env.VUE_APP_NEW_X_API_KEY
// secondHttp.defaults.headers.common.Authorization = 'Bearer ' + store.getters.token
secondHttp.interceptors.request.use(function (request) {
    if (!request.baseURL.indexOf(apiRouter.baseURL)) {
        // console.log('owner site')
    } else {
        // console.log('other site')
    }
    return request
}, function (error) {
    return error
})

secondHttp.interceptors.response.use(function (response) {
    return response.data
}, function (error) {
    return Promise.reject(error.response ||
        { response: { status: error.statusCode, message: error.message } })
})

export default secondHttp
